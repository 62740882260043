import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
   OverlayTrigger,
   Tooltip,
   Form,
   Accordion,
} from "react-bootstrap";
import { TooltipInfoIcon } from '@icons';
import { getToSettleChecklistDetail } from '@lib/api';
import { LevelContext } from '@components/contexts/LevelContext';
import ValidateSettleModal from './ValidateSettleModal';

const QuestionComponent = ({
   question,
   handleAnswersChange,
   handleValue,
   indexQ,
   checklistId,
   answerId,
   selectedQuestions,
   disabled,
   devMode,
}) => {
   const renderTooltip = (props) => (
      <Tooltip id='button-tooltip' {...props}>
         {question.description}
      </Tooltip>
   );

   return (
      <>
         <div id={`questionID-${question.code}`} name={question.code} questionid={question.code}>
            {Number(indexQ) !== 0 && <hr className='hr-question' />}
            <div className='row justify-content-start'>
               {devMode ?
                  <div className='col-1'>
                     <span className='question-code'>{question.code}</span>
                  </div> : null
               }

               <div className='col-1'>
                  <Form.Check
                     type="checkbox"
                     name="check"
                     onChange={(e) => handleAnswersChange(e, 'check', answerId)}
                     checked={selectedQuestions.answers.some(el => el.id === answerId && el.check)}
                  />
               </div>

               <div className={`col-10 ${devMode ? '' : ''}`}>
                  <div className='row'>
                     {question?.questionsFormatted?.length > 0
                        ? question.questionsFormatted
                        : question.questions}
                     {question?.objective?.length > 0 ? (
                        <span className='fw-bold text-start ps-0' style={{ fontSize: '.7rem' }}>
                           {question?.objective}
                        </span>
                     ) : (
                        ''
                     )}

                     {question.description ? (
                        <OverlayTrigger
                           placement='top'
                           delay={{ show: 200, hide: 400 }}
                           overlay={renderTooltip}>
                           <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                        </OverlayTrigger>
                     ) : null}
                  </div>

                  <div className='row mt-4'>
                     <div className='col-8 ps-0'>
                        <Form.Control
                           as='textarea'
                           rows={2}
                           disabled={disabled}
                           name={`${question.code}`}
                           placeholder='Non sono presenti note'
                           defaultValue={handleValue(checklistId, answerId, 'note')}
                        />
                     </div>
                     <div className='col-auto'>
                        <Form.Control
                           as='textarea'
                           rows={2}
                           name={`${question.code}`}
                           placeholder='es. Sanato'
                           defaultValue={handleValue(checklistId, answerId, 'tempNote')}
                           onChange={(e) => handleAnswersChange(e, 'note', answerId)}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

const CheckInvoice = ({ projectId, statementId, quotaId, selectedQuestions, setSelectedQuestions,
   showModalValidateSettle, setShowModalValidateSettle, setShowModalInfo, handleRenderData }) => {
   const { devMode } = useContext(LevelContext);
   const [questionsToSettle, setQuestionsToSettle] = useState([]);
   const [allQuestions, setAllQuestions] = useState([]);
   const [categories, setCategories] = useState([]);
   //console.log('questionsToSettle', questionsToSettle);
   //console.log('selectedQuestions:', selectedQuestions);

   const fetchToSettleChecklist = useCallback(() => {
      getToSettleChecklistDetail(projectId, statementId, quotaId).then(retrievedCl => {
         if (retrievedCl.success) {
            setQuestionsToSettle(retrievedCl.data);
            setAllQuestions(retrievedCl.data.flatMap((quest) => quest.answers));

            setCategories(retrievedCl.data
               .filter((quest, index, array) =>
                  array.findIndex((el) => el.entityType === quest.entityType) === index
               )
               .map((filteredElement) => filteredElement.entityType));
         }
      });
   }, [projectId, quotaId, statementId]);

   useEffect(() => {
      fetchToSettleChecklist();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      handleValue();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [questionsToSettle, selectedQuestions]);

   useEffect(() => {
      setQuestionsToSettle([]);
      setCategories([]);
   }, [quotaId]);

   const handleAnswersChange = (e, fieldName, answerId) => {
      let tempSelectedQuestionsAnsws = [...selectedQuestions.answers];
      let value;
      let indexAnswer = tempSelectedQuestionsAnsws.findIndex((el) => el.id === answerId);

      switch (fieldName) {
         case 'note':
            value = e.target.value;
            break;
         case 'check':
            value = e.target.checked;
            break;
         case 'checkAll':
            value = e.target.checked;

            if (value) {
               allQuestions.forEach(quest => {
                  if (tempSelectedQuestionsAnsws.some(el => el.id === quest.id)) {
                     let indexAnswer = tempSelectedQuestionsAnsws.findIndex((el) => el.id === quest.id);
                     tempSelectedQuestionsAnsws[indexAnswer].check = value;
                  } else {
                     tempSelectedQuestionsAnsws.push({ id: quest.id, check: value, note: '', quotaId: quotaId });
                  }
               });
            } else {
               tempSelectedQuestionsAnsws = tempSelectedQuestionsAnsws.map(el => ({ ...el, check: false }));
            }

            setSelectedQuestions({ answers: tempSelectedQuestionsAnsws });
            return;
         default:
            break;
      }

      if (tempSelectedQuestionsAnsws.length === 0 || indexAnswer === -1) {
         tempSelectedQuestionsAnsws.push({
            id: answerId,
            quotaId: quotaId,
            [fieldName]: value,
            ...(fieldName === 'note' ? { check: false } : {}),
            ...(fieldName === 'check' ? { note: '' } : {}),
         });
      } else {
         tempSelectedQuestionsAnsws[indexAnswer][fieldName] = value;
      }

      setSelectedQuestions({ answers: tempSelectedQuestionsAnsws });
   };

   const handleValue = (checklistId, answerId, type) => {
      let value;

      if (type === 'note') {
         let indexQuest = questionsToSettle.findIndex((el) => el.checklistId === checklistId);
         let indexAnswer = questionsToSettle[indexQuest]?.answers.findIndex((el) => el.id === answerId);

         value = questionsToSettle[indexQuest]?.answers[indexAnswer].question.note;
      } else if (type === 'tempNote') {
         let indexQuest = selectedQuestions.answers.findIndex((el) => el.id === answerId);
         value = selectedQuestions.answers[indexQuest]?.note;
      }

      return value ?? '';
   };

   const entityMapping = [
      {
         entity: 'Fattura',
         entityModel: 'App\\Models\\Invoice',
      },
      {
         entity: 'Attingimento',
         entityModel: 'App\\Models\\Contract',
      },
      {
         entity: 'Contratto',
         entityModel: 'App\\Models\\FrameworkContract',
      },
      {
         entity: 'Pagamento',
         entityModel: 'App\\Models\\InvoicePayment',
      },
      {
         entity: 'Gara',
         entityModel: 'App\\Models\\Tender',
      },
      {
         entity: 'DNSH',
         entityModel: 'App\\Models\\OTPlant',
      }
   ];

   const checkAnswersPresence = (selectedQuestions, questionsToSettle, quotaId) => {
      const questionSettle = questionsToSettle.find(q => q.quotaId === quotaId);

      if (!questionSettle) {
         return false;
      }

      return questionSettle.answers.every(answer =>
         selectedQuestions.answers.some(selectedAnswer => selectedAnswer.id === answer.id && selectedAnswer.check)
      );
   };

   return (<>
      <div>
         <Form id={'checklist-tosettle-invoice'} className='p-0'>
            <div className='row mb-3'>
               <div className='col-auto ms-4 ps-2'>
                  <Form.Check
                     inline
                     type="checkbox"
                     name="checkAll"
                     onChange={(e) => handleAnswersChange(e, 'checkAll')}
                     label={<span style={{ borderBottom: '1px solid #b3b3b3', color: '#09A8B0' }}>Seleziona tutto</span>}
                     checked={checkAnswersPresence(selectedQuestions, questionsToSettle, quotaId)}
                  />
               </div>
            </div>
            <div className='checklist-scroll' style={{ minHeight: '50vh', maxHeight: '73vh' }}>
               {questionsToSettle.length > 0
                  ? (<>
                     {categories?.sort()?.map((category, indexCat) => {
                        return (
                           <div className='row w-100' key={'cat-' + indexCat}>
                              <div className='col-lg-12'>
                                 <div>
                                    {questionsToSettle.filter(quest => quest.entityType === category).length > 0 && (
                                       <Accordion
                                          defaultActiveKey={indexCat + 1}
                                          style={{ marginBottom: '1.5rem' }}
                                          flush
                                          alwaysOpen>
                                          <Accordion.Item eventKey={indexCat + 1}>
                                             <Accordion.Header as='h5' className='accordion-title'>
                                                <div className='row w-100 align-items-center'>
                                                   <div className='col-1'></div>
                                                   <div className='col-8 pe-3'>
                                                      <div
                                                         className='d-flex title-simple-box margin-title-box align-items-center'
                                                         style={{ height: '100%' }}>
                                                         {entityMapping.filter(el => el.entityModel === category).map(el => el.entity)}
                                                      </div>
                                                   </div>

                                                </div>
                                             </Accordion.Header>
                                             <Accordion.Body className=''>
                                                {questionsToSettle.filter(quest => quest.entityType === category)?.map((question) => {
                                                   return question.answers.map((answer, indexQ) => {
                                                      return <QuestionComponent
                                                         key={'quest-' + answer.id}
                                                         question={answer.question}
                                                         handleAnswersChange={handleAnswersChange}
                                                         handleValue={handleValue}
                                                         indexQ={indexQ}
                                                         checklistId={question.checklistId}
                                                         answerId={answer.id}
                                                         selectedQuestions={selectedQuestions}
                                                         disabled={true}
                                                         devMode={devMode}
                                                      />
                                                   })
                                                })}
                                             </Accordion.Body>
                                          </Accordion.Item>
                                       </Accordion>
                                    )}
                                 </div>
                              </div>
                           </div>
                        );
                     })}
                  </>)
                  : 'Non ci sono domande da sanare'}
            </div>
         </Form>
      </div>

      <ValidateSettleModal
         selectedQuestions={selectedQuestions}
         setSelectedQuestions={setSelectedQuestions}
         showModalValidateSettle={showModalValidateSettle}
         setShowModalValidateSettle={setShowModalValidateSettle}
         setShowModalInfo={setShowModalInfo}
         handleRenderData={handleRenderData}
         quotaId={quotaId}
         isQuota={true}
      />
   </>
   );
};
export default CheckInvoice;