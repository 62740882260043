import React, { useState, useEffect } from 'react';
import {
   Button,
   Modal,
   Spinner,
} from "react-bootstrap";
import { settleChecklistAnswers } from '@lib/api';
import StatusBar from "@components/StatusBar";
import { OETrans } from "components/translation/OETrans";
import { translate } from "components/translation/translation";

const ValidateSettleModal = ({ selectedQuestions, setSelectedQuestions, showModalValidateSettle,
   setShowModalValidateSettle, setShowModalInfo, handleRenderData, handleSettleAll, quotaId, isQuota }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [sbShowValidateSettle, setSbShowValidateSettle] = useState(<></>);
   const [isInQuota, setIsInQuota] = useState(false);

   useEffect(() => {
      if (isQuota) setIsInQuota(true)
   }, [isQuota]);

   const handleStatusBar = (status, message, timeout, setSb = setSbShowValidateSettle) => {
      setSb(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const handleSettleQuota = async (e) => {
      //valida le domande di una singola quota
      e.preventDefault();
      setSbShowValidateSettle(<></>);
      setIsLoading(true);

      let tempSelectedQuestions = [...selectedQuestions.answers];

      const data = tempSelectedQuestions.filter(q => q.quotaId === quotaId && q.check).map(quest => {
         quest = { ...quest, note: quest.note ? quest.note.trim() : 'Sanato' };
         const { check, quotaId, ...restQuest } = quest;

         return restQuest;
      });

      let result = await settleChecklistAnswers({ answers: data });
      //console.log('result', result);

      if (result.success) {
         handleStatusBar(
            "success",
            "Elementi validati con successo",
            true,
            setSbShowValidateSettle
         );

         setTimeout(() => {
            setSelectedQuestions({ answers: [] });
            handleRenderData();
            setIsLoading(false);
            setShowModalValidateSettle(false);
            setShowModalInfo(false);
            setSbShowValidateSettle(<></>);
         }, 3000);
      } else {
         handleStatusBar(
            "error",
            result.message ??
            translate(
               "common.error.save",
               "Si è verificato un errore durante il salvataggio delle informazioni"
            ),
            false,
            setSbShowValidateSettle
         );
         setIsLoading(false);
      }
   };

   return (<>
       <Modal
         show={showModalValidateSettle}
         onHide={() => setShowModalValidateSettle(false)}
         centered
      >
         <Modal.Header closeButton>
            <Modal.Title>
               <OETrans code="common.warning" fallback={"Attenzione"} />
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="my-3">
               <span>Sei sicuro di voler procedere? Gli interventi sanatori non potranno essere più modificati.</span>
            </div>
            {sbShowValidateSettle}
         </Modal.Body>
         <Modal.Footer>
            <Button
               variant="secondary"
               onClick={() => setShowModalValidateSettle(false)}
            >
               <OETrans code="common.cancel" fallback={"Annulla"} />
            </Button>
            <Button
               variant="primary"
               type="submit"
               onClick={(e) => {isInQuota ? handleSettleQuota(e) : handleSettleAll()}}
            >
               Conferma
               {isLoading && (
                  <Spinner
                     className="ms-2"
                     as="span"
                     animation="border"
                     size="sm"
                     role="status"
                     aria-hidden="true"
                  />
               )}
            </Button>
         </Modal.Footer>
      </Modal>
   </>
   );
};
export default ValidateSettleModal;